import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorMessage, getNavigateBack, getNavigateTo, resetErrorMessage } from '../state/errorMessageSlicer';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, useTheme } from "@mui/material";
import Icon from '@mui/material/Icon';

const ErrorMessage = () => {

  console.log("show error")
  const dispatch = useDispatch();

  const theme = useTheme();

  const errorMessage = useSelector(getErrorMessage);
  const navigateBack = useSelector(getNavigateBack);
  const navigateTo = useSelector(getNavigateTo);

  console.log(errorMessage)


  if (errorMessage === "") return null;

  const passwordWasSuccessfullChanged = () => {
    if (navigateTo != null && navigateTo !== "") {
      dispatch(resetErrorMessage());
      // Use your routing logic here (e.g., React Router, Next.js Link, etc.)
    } else {
      if (navigateBack) {
        dispatch(resetErrorMessage());
        // Use your routing logic here to go back
      } else {
        dispatch(resetErrorMessage());
      }
    }
  };

  return (
    // <div className='absolute bottom-0 left-0 top-0 right-0 z-50 justify-center items-center' style={{ backgroundColor: `${theme.palette.secondary[200]} !important` }}>
    //   <div className='ml-3 mr-3 bg-white rounded-lg'>
    //     <div className="m-4">
    //       <div>
    //         <p className='text-black font-extrabold text-base text-center'>{errorMessage}</p>
    //       </div>

    //       <button onClick={passwordWasSuccessfullChanged} className='mt-10 bg-[#1e1b4b] p-4 rounded-lg items-center ml-5 mr-5 mb-5'>
    //         <span className='text-white font-extrabold text-lg text-center'>ok</span>
    //       </button>
    //     </div>
    //   </div>
    // </div>
    <Dialog open={passwordWasSuccessfullChanged} onClose={passwordWasSuccessfullChanged}>
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}> <Icon>error</Icon>Error</DialogTitle>
        <DialogContent>
          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
              {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={passwordWasSuccessfullChanged} color="primary" sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
            OK
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default ErrorMessage;
