import { Typography, Box, useTheme, Button } from "@mui/material";
import React from "react";
import Icon from '@mui/material/Icon';

const Header = ({ title, subtitle, buttons, iconName }) => {
  const theme = useTheme();


  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography
          variant="h2"
          color={theme.palette.secondary[100]}
          fontWeight="bold"
          mb={1}
        >
          {title}
        </Typography>
        <Typography variant="h5" color={theme.palette.secondary[300]}>
          {subtitle}
        </Typography>
      </Box>
      <Box>
        {buttons &&
          buttons.map((button, index) => (
            <Button key={index} variant="contained" 
              onClick={button.onClick}
              sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              m: "0rem 0.5rem 0rem 0rem" ,
              "&:hover": { color: theme.palette.secondary.light },
            }}>
              {button.iconName && <Icon>{button.iconName}</Icon>}
              {button.label}
            </Button>
          ))}
      </Box>
    </Box>
  );
};

export default Header;