import React, { useEffect, useState } from "react";
import { Box, useTheme, Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetTransactionsQuery } from "state/api";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { Delete, Edit, Info, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Transactions = () => {
  const theme = useTheme();
  var isAuth = useSelector((state) => state.auth.token);

  console.log(isAuth)

  const navigate = useNavigate();

  console.log("new")

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");

  const [searchInput, setSearchInput] = useState("");
  // const { data, isLoading } = useGetTransactionsQuery({
  //   isAuth,
  //   page,
  //   limit,
  //   sort: JSON.stringify(sort),
  //   search,
  // });

  const { data, isLoading, refetch } = useGetTransactionsQuery({
      isAuth,
      page,
      limit,
      sort: JSON.stringify(sort),
      search,
    });

  useEffect(() => {
    // Fetch the data when the component mounts
    refetch();
  }, [refetch]);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "userId",
      headerName: "User ID",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "CreatedAt",
      flex: 1,
    },
    {
      field: "products",
      headerName: "# of Products",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => params.value.length,
    },
    {
      field: "cost",
      headerName: "Cost",
      flex: 1,
      renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
    { field: 'actions', headerName: 'Actions', width: 100, renderCell: (params) => {
      return (
        <Button
          variant="primary"
          size="small"
          onClick={() =>  navigate(`/transactions/${params.row._id}`)}
        >


          <Info />
          <Edit />
              <Delete />
        </Button>
      );
    } }
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="TRANSACTIONS" subtitle="Entire list of transactions" />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.transactions) || []}
          columns={columns}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={limit}
          paginationMode="server"
          sortingMode="server"
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          onPaginationModelChange={(paginationModel) => setPage(paginationModel.page)} //setPageSize(paginationModel.pageSize)
          slots={{ Toolbar: DataGridCustomToolbar }}
          slotProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        />
      </Box>
    </Box>
  );
};

export default Transactions;