import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    showLoading:false,
    loadingMessage: ""
  },
  reducers: {
    showLoading: ( state, action) => {

      state.showLoading = true;
      state.loadingMessage = action.payload

    },
    hideLoading: ( state, action) => {
        state.showLoading = false;
        state.loadingMessage = "";
    }
  }
})

// Action creators are generated for each case reducer function
export const { showLoading, hideLoading } = loadingSlice.actions

export const getShowLoading = (state) => state.loading.showLoading

export const getLoadingMessage = (state) => state.loading.loadingMessage


export default loadingSlice.reducer