import { createSlice } from '@reduxjs/toolkit'

export const errorMessageSlice = createSlice({
  name: 'basket',
  initialState: {
    items: [],
    errorMessage: "",
    navigateBack: false,
    navigateTo: ""
  },
  reducers: {
    showError: ( state, action) => {

      state.errorMessage = action.payload

    },
    showErrorGoBack: ( state, action) => {

      state.errorMessage = action.payload
      state.navigateBack = true
      state.navigateTo = ""
    },
    showErrorGoTo: ( state, action) => {

      console.log(action.payload)
      state.errorMessage = action.payload.message
      state.navigateBack = true
      state.navigateTo = action.payload.navigateTo
    },
    resetErrorMessage: ( state, action) => {
        state.errorMessage = "";
        state.navigateBack = false;
        state.navigateTo = ""
    }
  }
})

// Action creators are generated for each case reducer function
export const { showError, showErrorGoBack, showErrorGoTo, resetErrorMessage } = errorMessageSlice.actions

export const getErrorMessage = (state) => state.error.errorMessage
export const getNavigateBack = (state) => state.error.navigateBack
export const getNavigateTo = (state) => state.error.navigateTo

export default errorMessageSlice.reducer