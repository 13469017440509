import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: "/"}),
  reducerPath: "adminApi",
  tagTypes: [
    "Locathions",
    "User",
    "Products",
    "Customers",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getLocations: build.query({
      query: ({ page, limit, sort, search }) => ({
        url: "api/localhost/123/frontdesk",
        method: "GET",
        params: { page, limit, sort, search },
      }),
      providesTags: ["Locathions"],
    }),
    getProducts: build.query({
      query: (sessionToke) => `api/localhost/${sessionToke}/users`,
      providesTags: ["Products"],
    }),
    getCustomers: build.query({
      query: (sessionToke) => `api/localhost/${sessionToke}/users`,
      providesTags: ["Customers"],
    }),
    getTransactions: build.query({
      query: ({ sessionToke, page, limit, sort, search }) => ({
        url: `api/localhost/782a2628718013686d44a549a069002b5521c894e1ed28dbce90c7266f6ec81e/rewardany`,
        method: "GET",
        params: { page, limit, sort, search },
      }),
      providesTags: ["Transactions"],
    }),
    getGeography: build.query({
      query: () => "client/geography",
      providesTags: ["Geography"],
    }),
    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    getAdmins: build.query({
      query: () => "management/admins",
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      query: () => "general/dashboard",
      providesTags: ["Dashboard"],
    }),

    getRewardAny: build.query({
      query: (sessionToke) => `msmm/api/localhost/${sessionToke}/rewardany` ,
      providesTags: ["Customers"],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useGetUserQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,

  useGetRewardAnyQuery,

} = api;