import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import Header from "components/Header";
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "state/loadingSlicer";
import DialogYesNo from "components/dialog/DialogYesNo";
import { getRewardAnyAdvertisers, getRewardAnyClickReport } from "../../hooks/HttpRequests";
import { showError } from "state/errorMessageSlicer";
import {  useNavigate } from "react-router-dom";


const RewardAnyClickReport = () => {
  var isAuth = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const theme = useTheme();

  /////////////// GRID /////////////
  // values to be sent to the backend
  let emptyData = { data: [], total_count: 0 };
  const [data, setData] = useState(emptyData);
  const [gridLoading, setGridLoading] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // Hide columns status and traderName, the other columns will remain visible
    entityId: false,
  });

  const [search, setSearch] = useState("");

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    limit: 25,
    sort: [{"property":"entityId","direction":"DESC"}],
    query:[]
  })

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  
  useEffect(() => {
    // This code will run every time paginationModel changes
    loadGridData();

  }, [paginationModel]);


  const handleFilterModelChange = (filterModel) => {
    // Update the filter model state when it changes

    console.log("handleSortModelChange()")

    // Convert to the desired structure
    const desiredStructure = filterModel.items.map(item => {
      let mappedOperator = item.operator;
    
      let field = columns.find(column => column.field === item.field);
      var fieldType = "string";

      if(typeof field.type !== 'undefined'){
        fieldType = field.type;
      }

      // Map operators as needed
      if (item.operator === 'equals') {
        mappedOperator = 'eq';
      } else if (item.operator === 'contains') {
        mappedOperator = 'like';
      } // Add more conditions as needed
    
      return {
        property: item.field,
        value: item.value,
        type: fieldType, // You might need to adjust this based on your data types
        operator: mappedOperator
      };
    });

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      query: desiredStructure,
      page: 0,
    }));

  };

  const handleSortModelChange = (sortModel) => {
    // Handle sorting changes and fetch data from the server
    //sort: [{"property":"lastname","direction":"ASC"}]

    console.log("handleSortModelChange()")

    const sortObject = sortModel.map(item => ({
      property: item.field,
      direction: item.sort.toUpperCase() // Assuming you want direction in uppercase (ASC or DESC)
    }));

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      sort: sortObject,
      page: 0,
    }));

  
  };

  const handlePageChange = (newPage) => {
    // Handle page changes and fetch data from the server

    console.log("handlePageChange()")

    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPage.page,
      limit: newPage.pageSize,
    }));

  };

  const loadGridData = async () => {

    setGridLoading(true)

    getRewardAnyClickReport(paginationModel)
      .then((result) => {

        setData(result)
        setGridLoading(false)

      })
      .catch((error) => {

        setData(emptyData);

        if(typeof error.action !== 'undefined'){
            if(error.action == 'relogin'){
              navigate('/');
            }

        }

        setGridLoading(false)
      });

  }

  const handleExecute = async (values) => {



    dispatch(showLoading( "starting sync"))

    const token = localStorage.getItem('token');

    const loggedInResponse = await fetch(`api/localhost/${token}/rewardany/sync/click_report`, {
      method: "GET",
      // headers: { "Content-Type": "application/json" },
      // body: JSON.stringify(values),
    });
    
      dispatch(hideLoading());

      setConfirmationOpen(false);

    

  };

  const columns = [
    
    {
      field: "entityId",
      headerName: "Entity Id",
      flex: 1,
      hide: true,
      editable: true,
      type: 'number'
    },
    {
      field: "advertiserId",
      headerName: "Advertiser Id",
      type: 'number'
    },
    {
      field: "advertiserName",
      headerName: "Advertiser Name",
      flex: 1,
    },
    {
      field: "dateGMT",
      headerName: "Date",
      type: 'date',
      valueGetter: (params) => {
        // Parse the date string to a Date object
        const parsedDate = Date.parse(params.value);
        
        // Check if parsing was successful
        if (!isNaN(parsedDate)) {
          return new Date(parsedDate);
        } else {
          console.error('Error parsing date:', params.value);
          return null; // or handle the error in a way that suits your application
        }
      },
    },
    {
      field: "clickCount",
      headerName: "Click Count",
      type: 'number'
    },
  ];

  const buttons = [
    // { label: "Button 1" },
    { label: "Start Sync" , onClick: () => setConfirmationOpen(true) , iconName: 'sync'},

  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="REWARDANY" subtitle="List of click report" buttons={buttons} />
      <DialogYesNo
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleExecute}
        theme={theme}
      />
      {/* </Box> */}
      <Box
        mt="40px"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },


          "& .MuiDataGrid-panel .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
         "& .MuiDataGrid-panelWrapper .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
          "& .MuiDataGrid-filterFormDeleteIcon": {
            color: `${theme.palette.secondary[200]} !important`,
            backgroundColor:  `${theme.palette.secondary[200]} !important`,

          },
         
          
        }}
      >
        <DataGridPro
          loading={gridLoading || !data}
          getRowId={(row) => row.entityId}
          rows={data?.data || []}
          columns={columns}
          rowCount={(data && data.total_count) || 0}
          rowsPerPageOptions={[25, 50, 100]}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }

          filterMode="server"
          onFilterModelChange={handleFilterModelChange}

          sortingMode="server"
          onSortModelChange={handleSortModelChange}

          paginationMode="server"
          pagination
          page={paginationModel.page}
          pageSize={paginationModel.limit}
          onPaginationModelChange={(paginationModel) => handlePageChange(paginationModel)} //setPageSize(paginationModel.pageSize)

          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            filterPanel: {
              logicOperators: [] //disable and or or. its always a and for me
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default RewardAnyClickReport;