import {
  Box,
  Typography,
  useTheme,
} from "@mui/material"
import React from 'react'
import {  useSelector } from 'react-redux'
import { getLoadingMessage, getShowLoading } from '../state/loadingSlicer'
import { Oval } from "react-loader-spinner"
// import {ActivityIndicator, StyleSheet, View} from 'react-native';

const LoadingMessage = () => {

  const theme = useTheme();

  const showLoading = useSelector(getShowLoading);

  const loadingMessage = useSelector(getLoadingMessage);
 

  // if(items.length === 0) return null;
  if(!showLoading) return null;

  const containerStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: loadingMessage !== "" ? '#FFFFFF' : '#1e1b4b90',
    zIndex: 50,
  };

  const boxStyle = {
    backgroundColor: loadingMessage !== "" ? '#FFFFFF' : 'transparent',
    borderRadius: '0.375rem',
    padding: '1.5rem',
    textAlign: 'center',
  };

  return (
    // <View className='absolute bottom-0 left-0 top-0 right-0 z-50 justify-center items-center bg-white' style={{backgroundColor: '#1e1b4b90'}}>
    // <Box className={`absolute bottom-0 left-0 top-0 right-0 z-50 justify-center items-center ${errorMessage !== "" && "bg-white"}`} style={{backgroundColor: '#1e1b4b90'}}>
    // <div style={containerStyle}>
      <Box
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: loadingMessage !== "" ? '#21295c80'  : '#21295c80', //the 80 at the end is transparency
              zIndex: 50,
            }}
            sx={{
              borderRadius: '0.375rem',
              p: '1.5rem',
              textAlign: 'center',
            }}
          >
        {/* <View className='ml-3 mr-3 rounded-lg'> */}
        {/* <Box className={`ml-3 mr-3 rounded-lg ${loadingMessage !== "" && "bg-black"}`} > */}
        {/* <div style={boxStyle}> */}
        <Box m="1.5rem 2rem 2rem 3rem" sx={{ backgroundColor: theme.palette.secondary[100] }}>

          {/* <Box className="m-4"> */}
            <Box>
              {/* <Text className='mb-5 text-black font-extrabold text-base text-center'>{errorMessage}</Text> */}
            </Box>
              <Typography variant="h4" fontWeight="bold" sx={{ color: theme.palette.secondary[100] }}>
              {loadingMessage}
            </Typography>

            {/* <ActivityIndicator size={'large'}/> */}
            <Oval
              visible={true}
              height="80"
              width="80"
              color="#ffedc2"
              secondaryColor="#ffedc2"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
              />
          {/* </Box> */}
        </Box>
    </Box>
  )
}

export default LoadingMessage