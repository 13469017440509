import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material";
import React from 'react'


const DialogYesNo = ({ open, onClose, onConfirm, theme }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Paper sx={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}>
        <DialogTitle sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText color="primary" sx={{ color: theme.palette.background.alt }}>
            Are you sure you want to execute this action?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" sx={{ color: theme.palette.background.alt }}>
            No
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus sx={{ color: theme.palette.background.alt, fontWeight: "bold", }}>
            Yes
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default DialogYesNo