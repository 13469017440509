import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Products from "scenes/products";
import Customers from "scenes/customers";
import Transactions from "scenes/transactions";
import Geography from "scenes/geography";
import Overview from "scenes/overview";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";
import Admin from "scenes/admin";
import Performance from "scenes/performance";
import TransactionsInfo from "scenes/transactions/info";
import LoginPage from "scenes/login";
import ResetPage from "scenes/resetpassword";
import Contacts from "scenes/contacts";
import RewardAny from "scenes/rewardany";
import RewardAnyTransactions from "scenes/rewardany/transactions";
import RewardAnyClickReport from "scenes/rewardany/clickreport";
// import InvitationPage from "scenes/invitation/create";
// import Locations from "scenes/locations";
import { LicenseInfo } from '@mui/x-license-pro';
import FlexOffersTransactionsMediaBuying from "scenes/flexoffers/transactionsmediabuying";
import FlexOffersTransactionsForexTrading from "scenes/flexoffers/transactionsforextrading";


function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  LicenseInfo.setLicenseKey('9cbdf45defcb781d8d52a84d97b5c829Tz04NTczOSxFPTE3NDEyMjAyNzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

  var isAuth = useSelector((state) => state.auth.token);
  if(isAuth !== ""){
    isAuth = true;
  }else{
    isAuth = false;
  }


  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={!isAuth ? <LoginPage /> : <Navigate to="/dashboard"  />} />
            <Route path="/reset" element={<ResetPage />} />

            <Route element={<Layout />}>
              {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
              <Route path="/dashboard" element={isAuth ? <Dashboard /> : <Navigate to="/"  />} />
              {/* <Route path="/invitation/create" element={isAuth ? <InvitationPage /> : <Navigate to="/"  />} /> */}

              {/* <Route path="/locations" element={isAuth ? <Locations /> : <Navigate to="/"  />} /> */}

              <Route path="/advertiser" element={<RewardAny />} />
              <Route path="/click_report" element={<RewardAnyClickReport />} />
              <Route path="/transactions" element={<RewardAnyTransactions />} />

              <Route path="/transactions_media_buying" element={<FlexOffersTransactionsMediaBuying />} />
              <Route path="/transactions_forex_trading" element={<FlexOffersTransactionsForexTrading />} />

              <Route path="/users" element={<Customers />} />

              

              <Route path="/contacts" element={<Contacts />} />
              

              <Route path="/products" element={<Products />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/transactions/:id" element={<TransactionsInfo />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/overview" element={<Overview />} />
              <Route path="/daily" element={<Daily />} />
              <Route path="/monthly" element={<Monthly />} />
              <Route path="/breakdown" element={<Breakdown />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/performance" element={<Performance />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;