// apiRequests.js

import axios from 'axios';

const getUri = (token) => {
  // Implementation for generating the base URL based on the token
  // Replace this with your actual implementation

  let URI = "/api/localhost/"+((token != null && token != '') ? token+"/":'')+"";
    // URI = "https://app.softpowerapp.com/api/localhost/"+((token != null && token != '') ? token+"/":'')+"app/";
    // URI = "https://softpower-env.eba-pdd3fyar.us-east-2.elasticbeanstalk.com/api/localhost/"+((token != null && token != '') ? token+"/":'');

  return URI;
};

const getUserAgent = () => {
  // Implementation for getting the user agent
  // Replace this with your actual implementation
  return 'Your User Agent';
};

const commonHeaders = {
  'Content-Type': 'application/json',
  // 'user-agent': getUserAgent(),
};

const getRequestParameters = (requestParams) => {
  // Implementation for getting the user agent
  // Replace this with your actual implementation
  var params = "";

  try{
    
    params = "?start=".concat(requestParams.page * requestParams.limit).concat("&page=").concat(requestParams.page).concat("&limit=").concat(requestParams.limit)

    if(requestParams.sort.length > 0){

      const sortJsonString = JSON.stringify(requestParams.sort);
      params = params.concat("&sort=").concat(encodeURIComponent(sortJsonString));

    }
    if(requestParams.query.length > 0){

      const queryJsonString = JSON.stringify(requestParams.query);

      console.log(queryJsonString)
      params = params.concat("&query=").concat(encodeURIComponent(queryJsonString));

    }

  }catch(error){

  }


  return params;
};

const getSessionToken = () => {

  const token = localStorage.getItem('token');

  return token;

}

const setSessionToken = (sessionToken) => {

  const token = localStorage.getItem(sessionToken);

  return token;

}


////////////////////////////////////////////////////////////////

export const getRewardAnyAdvertisers = (requestParams) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `rewardany` + getRequestParameters(requestParams);

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      console.error(error);

      // Check for specific error types or handle general errors
      if (axios.isAxiosError(error) && error.response) {
        const { status } = error.response;
        if (status === 400) {
          // Handle 400 Bad Request, not logged in
          setSessionToken("");
          console.error('Bad Request:', error.response.data);
          reject( error.response.data);

        }
      }else{
        reject(error);
      }
      
    }
  });
};

export const getRewardAnyAdvertisersToggleActiveCampaign = (entityId) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `rewardany` + '/'+entityId+'/activecampaign/toggle';

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      console.error(error);

      // Check for specific error types or handle general errors
      if (axios.isAxiosError(error) && error.response) {
        const { status } = error.response;
        if (status === 400) {
          // Handle 400 Bad Request, not logged in
          setSessionToken("");
          console.error('Bad Request:', error.response.data);
          reject( error.response.data);

        }
      }else{
        reject(error);
      }
      
    }
  });
};

export const getRewardAnyClickReport = (requestParams) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `rewardany/click_report` + getRequestParameters(requestParams);

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      console.error(error);

      // Check for specific error types or handle general errors
      if (axios.isAxiosError(error) && error.response) {
        const { status } = error.response;
        if (status === 400) {
          // Handle 400 Bad Request
          console.error('Bad Request:', error.response.data);
          reject( error.response.data);

        }
      }else{
        reject(error);
      }
      
    }
  });
};

export const getRewardAnyTransactions = (requestParams) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `rewardany/transactions` + getRequestParameters(requestParams);

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      console.error(error);

      // Check for specific error types or handle general errors
      if (axios.isAxiosError(error) && error.response) {
        const { status } = error.response;
        if (status === 400) {
          // Handle 400 Bad Request
          console.error('Bad Request:', error.response.data);
          reject( error.response.data);

        }
      }else{
        reject(error);
      }
      
    }
  });
};

export const getFlexOffersSales = (requestParams) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `flexoffers/sales/mediabuying` + getRequestParameters(requestParams);

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      console.error(error);

      // Check for specific error types or handle general errors
      if (axios.isAxiosError(error) && error.response) {
        const { status } = error.response;
        if (status === 400) {
          // Handle 400 Bad Request
          console.error('Bad Request:', error.response.data);
          reject( error.response.data);

        }
      }else{
        reject(error);
      }
      
    }
  });
};

export const getFlexOffersSalesForextrading = (requestParams) => {


  return new Promise(async (resolve, reject) => {
    try {

      var token = getSessionToken();

      if(token == ''){
          reject( {"success" : false, "action" : "relogin"});
      }

      const url = getUri(token) + `flexoffers/sales/forextrading` + getRequestParameters(requestParams);

      const config = {
        headers: commonHeaders,
      };

      const response = await axios.get(url, config);

      if (response.data.success) {
        // Additional logic if needed
        resolve(response.data);
      } else {
        // Additional error handling logic
        reject(response.data);
      }
    } catch (error) {
      console.error(error);

      // Check for specific error types or handle general errors
      if (axios.isAxiosError(error) && error.response) {
        const { status } = error.response;
        if (status === 400) {
          // Handle 400 Bad Request
          console.error('Bad Request:', error.response.data);
          reject( error.response.data);

        }
      }else{
        reject(error);
      }
      
    }
  });
};
// Add more reusable functions as needed
